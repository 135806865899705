import React, { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  Card,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReceptionForm from "../forms/ReceptionForm";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddNewReception = ({
  openCanvas,
  onlyClose,
  itemDate,
  returnNewDate,
  viewExpedient,
  foundationList,
  studyDataList,
}) => {
  const [open, setOpen] = useState(false);

  
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
    } else {
      setOpen(false);
      onlyClose();
    }
  };

  useEffect(() => {
    setOpen(openCanvas);
  }, [openCanvas]);

  const onRetun = (value) => {
    returnNewDate(value);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          backgroundColor: "#EC7BA1",
          borderRadius: "20px 20px 0px 0px",
          position: "fixed",
          right: 0,
          bottom: 0,
          marginRight: 0,
          marginBottom: 0,
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle id="dialog-title-style-1">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              Registro de cita
            </Typography>
          </Grid>
          <Grid item id="grid-style-8" xs={6}>
            <IconButton onClick={handleClose} sx={{ color: "white" }}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent id="dialog-content-style-1">
        <Card id="card-style-3">
          <Box id="box-style-2">
            <ReceptionForm
              itemDate={itemDate}
              returnNewDate={onRetun}
              viewExpedient={viewExpedient}
              foundationList={foundationList}
              studyDataList={studyDataList}
            />
          </Box>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewReception;
