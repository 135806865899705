export const genderData = [
    {
      id: 1,
      gender: "Femenino",
    },
    {
      id: 2,
      gender: "Masculino",
    },
    {
      id: 3,
      gender: "Otro",
    },
  ];
  export const reasonsData = [
    {
      id: 1,
      reason: "Preventivo",
    },
    {
      id: 2,
      reason: "Control",
    },
    {
      id: 3,
      reason: "Otro",
    },
  ];
  export const referredData = [
    {
      id: 1,
      referred: "XMI",
    },
    {
      id: 2,
      referred: "Otro",
    },
  ];

  export const branchOffice = [
    {
      id: 1,
      officename: "IMSS",
    },
    {
      id: 2,
      officename: "Condesa",
    },
    {
      id: 3,
      officename: "GSMITT",
    },
  ];

  export const occupation = [
    {
      id: 1,
      officename: "Medico",
    },
    {
      id: 2,
      officename: "Asistente",
    },
    {
      id: 3,
      officename: "Tecnico",
    },
    {
      id: 4,
      officename: "Recepcionista",
    },
    {
      id: 5,
      officename: "Director",
    },
  ];
